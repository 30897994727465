import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Té un capell de quasi 20 cm de diàmetre, al principi esfèric i després convex a quasi pla. És vellutat i de color variable entre groc argila, groguenc oliva o groc terrós. Té tubs curts, rodejant el peu i de color groc clar a groc or o inclús verd oliva. Els porus del mateix color que els tubs són rodons o angulosos. Les espores són marró olivàcies en massa, el·líptiques o fusiformes, llises, de 9,5-15 x 4-6 micres. El peu de color groc i a la part inferior marró rogenc, és ovoïdal de jove i després més llarg i prim cap a la base i presenta unes estries longitudinals.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      